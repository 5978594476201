import React from "react";

function HistoryPanel() {
  return (
    <aside className="flex flex-col ml-5 w-[24%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col w-full text-base leading-tight text-white whitespace-nowrap rounded border border-white border-solid max-md:mt-4">
        <div className="flex gap-5 justify-between px-5 py-2 rounded-none bg-slate-700 bg-opacity-30 max-md:pr-5">
          <div>History:</div>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/e8be15af075d03ad913484c0653c6526becfd9b93bfb2fbdcdf361620f7d3dae?placeholderIfAbsent=true&apiKey=89cf183a92e74cf6ac32b5c47e264d6f" alt="History icon" className="object-contain shrink-0 my-auto w-4 aspect-square" />
        </div>
      </div>
    </aside>
  );
}

export default HistoryPanel;