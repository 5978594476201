import React, { useState } from "react";
import DeviceItem from "./DeviceItem";
import { useSelector } from "react-redux";

function DeviceList() {
  const devices = useSelector(state => state.devices.devices);
  const selectedDistrictId = useSelector(state => state.mapState.selectedDistrictId);
  const [sortConfig, setSortConfig] = useState({ key: 'location', direction: 'asc' });

  const filteredDevices = devices.filter(device => device.district === selectedDistrictId);

  const sortedDevices = [...filteredDevices].sort((a, b) => {
    if (!sortConfig.key) return 0;
    
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];
    
    if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
    if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
    return 0;
  });

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (columnName) => {
    if (sortConfig.key !== columnName) {
      return <span className="inline-block w-[12px] ml-2" />;
    }
    return sortConfig.direction === 'asc' 
      ? <span className="inline-block w-0 h-0 ml-2 border-x-[6px] border-x-transparent border-b-[8px] border-b-gray-600 translate-y-[-2px]" />
      : <span className="inline-block w-0 h-0 ml-2 border-x-[6px] border-x-transparent border-t-[8px] border-t-gray-600 translate-y-[-2px]" />;
  };

  return (
    <div className="h-full w-full pl-4">
      <div>
        <table className="table-auto w-full">
          <thead className="sticky top-0 bg-white z-10">
            <tr className="text-left">
              <th className="p-2"></th>
              <th className="p-2 cursor-pointer" onClick={() => requestSort('sensor_id')}>
                Device ID {getSortIcon('sensor_id')}
              </th>
              <th className="p-2 cursor-pointer" onClick={() => requestSort('location')}>
                Location {getSortIcon('location')}
              </th>
              <th className="p-2">Position</th>
              <th className="p-2 cursor-pointer" onClick={() => requestSort('status')}>
                Status {getSortIcon('status')}
              </th>
              <th className="p-2"></th>
            </tr>
          </thead>
          <tbody>
            {sortedDevices.map((device, index) => (
              <DeviceItem key={index} {...device} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DeviceList;
