import React from 'react';

const InfoBox = ({ type, readings }) => {
  const getStatusClass = (status) => {
    switch (status) {
      case "1": return "text-status-success";
      case "2": return "text-status-warning";
      case "3": return "text-status-error";
      default: return "text-white";
    }
  };

  return (
    <div className="flex flex-col px-2 py-1.5 text-xs rounded bg-slate-700 w-[170px]">
      <div className="flex gap-5 justify-between text-white">
        <div>Type:</div>
        <div className="text-right">{type}</div>
      </div>
      {readings.map((reading, index) => (
        <div key={index} className="flex gap-5 justify-between mt-1.5">
          <div className="text-white">{reading.key}:</div>
          <div className={`text-right ${getStatusClass(reading.status)}`}>
            {reading.value}
          </div>
        </div>
      ))}
    </div>
  );
};

export default InfoBox;