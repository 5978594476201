// src/redux/devicesSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  devices: [],
};

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setDevices: (state, action) => {
      state.devices = action.payload;
    },
    scheduleMaintenance: (state, action) => {
      const { deviceId, maintenance } = action.payload;
      const deviceIndex = state.devices.findIndex(device => device.sensor_id === deviceId);
      if (deviceIndex !== -1) {
        const device = state.devices[deviceIndex];
        const newMaintenances = device.maintenances
          ? [...device.maintenances, maintenance]
          : [maintenance];
        state.devices[deviceIndex] = {
          ...device,
          maintenances: newMaintenances,
        };
      }
    },

  },
});

export const { setDevices, scheduleMaintenance } = devicesSlice.actions;

export default devicesSlice.reducer;
