import React from 'react';
import StatusIndicator from './StatusIndicator';

const StatusIndicatorGroup = ({statusCounts}) => {
  const statuses = [
    { color: 'bg-red-500', count: statusCounts.status3 },
    { color: 'bg-amber-300', count: statusCounts.status2 },
    { color: 'bg-green-400', count: statusCounts.status1 },
  ];

  return (
    <div className="flex relative gap-2.5">
      {statuses.map((status, index) => (
        <StatusIndicator key={index} color={status.color} count={status.count} />
      ))}
    </div>
  );
};

export default StatusIndicatorGroup;