import { useRef, memo } from 'react';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import './MarkerMaker.css';
import MapContent from './components/MapContent';
import InspectorPanel from './components/InspectorPanel';
import { useMarkerManager } from './hooks/useMarkerManager';
import { useSelector } from 'react-redux';
import Controls from './components/Controls';

// Memoize MapContent
const MemoizedMapContent = memo(MapContent);

export default function MarkerMaker() {
  const mapRef = useRef(null);
  const selectionBoxRef = useRef(null);
  const mapSettings = useSelector((state) => state.settings);

  const handlers = useMarkerManager(mapRef, selectionBoxRef);

  return (
    <APIProvider apiKey="AIzaSyCBh1WTEHMpJrPNFBzZhplqCpsJlmisigY">
      <div>
        <Controls />
        <div style={{ position: 'relative' }} ref={mapRef} className="map-container">
          <Map
            defaultZoom={mapSettings.zoom}
            defaultCenter={mapSettings.center}
            clickableIcons={false}
            mapTypeControlOptions={{
              position: 20,
              style: 1,
            }}
            options={{
              disableDefaultUI: false,
              zoomControl: true,
              streetViewControl: true,
              fullscreenControl: false,
              mapId: mapSettings.mapId,
              clickableIcons: false,
              disableDoubleClickZoom: true,
              draggableCursor: 'default',
              draggingCursor: 'default'
            }}
            style={{ userSelect: 'none' }}
          >
            <MemoizedMapContent
              onMapClick={handlers.handleMapClick}
              onMarkerClick={handlers.handleMarkerClick}
              onMarkerDragEnd={handlers.handleMarkerDragEnd}
            />
          </Map>
          
          <div ref={selectionBoxRef} className="selection-box" />
          
          <InspectorPanel />
        </div>
      </div>
    </APIProvider>
  );
} 