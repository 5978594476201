import React from 'react';
import styles from './Dashboard.module.css';

const InfoItem = ({ iconSrc, text }) => (
  <div className={styles.infoItem}>
    <img loading="lazy" src={iconSrc} alt="" className={styles.icon} />
    <div className={styles.infoText}>{text}</div>
  </div>
);

export default InfoItem;