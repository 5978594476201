import React, { useCallback } from 'react';
import DeviceInformation from '../device_cards/ai_card/DeviceInformation';
import './DeviceTableRow.css';

const DeviceTableRow = ({ 
  device,
  isExpanded,
  onExpandClick,
  getUpcomingMaintenances,
  getStatusColor,
  getStatusText
}) => {
  const handleClick = useCallback(() => {
    onExpandClick(device.sensor_id);
  }, [onExpandClick, device.sensor_id]);

  return (
    <React.Fragment>
      <tr 
        className="hover:bg-gray-50 cursor-pointer"
        onClick={handleClick}
      >
        <td className="px-6 py-4 whitespace-nowrap">
          <img src="/walterodevice.png" alt="Device" className="h-8 w-8" />
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {new Date().toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          })} - {new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          })}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">#{device.sensor_id}</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{device.district_name}</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{device.type || 'NA'}</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{getUpcomingMaintenances(device)}</td>
        <td className="px-6 py-4 whitespace-nowrap">
          <span className="flex items-center">
            <span className={`h-2.5 w-2.5 rounded-full mr-2 ${getStatusColor(device.status)}`}></span>
            <span className="text-sm text-gray-500">{getStatusText(device.status)}</span>
          </span>
        </td>
      </tr>
      {isExpanded && (
        <tr className={`${isExpanded ? 'expanded-row' : 'unexpanded-row'}`}>
          <td colSpan="7" className="px-6 py-4">
            <div className="overflow-hidden transition-all duration-300 ease-in-out">
              <DeviceInformation device={device} className="" />
            </div>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default React.memo(DeviceTableRow, (prevProps, nextProps) => {
  return (
    prevProps.device === nextProps.device &&
    prevProps.isExpanded === nextProps.isExpanded &&
    prevProps.getUpcomingMaintenances === nextProps.getUpcomingMaintenances &&
    prevProps.getStatusColor === nextProps.getStatusColor &&
    prevProps.getStatusText === nextProps.getStatusText
  );
}); 