import React from 'react';
import styles from './Dashboard.module.css';

const StatusItem = ({ color, value }) => (
  <div className={styles.statusItem}>
    <div className={`${styles.statusIndicator} ${styles[`${color}Indicator`]}`} />
    <div>{value}</div>
  </div>
);

export default StatusItem;