import { useEffect } from 'react';
import { useMap } from '@vis.gl/react-google-maps';

const MapController = ({ onClick }) => {
  const map = useMap();
  
  useEffect(() => {
    if (!map) return;
    
    let isShiftDown = false;

    const handleKeyDown = (e) => {
      if (e.key === 'Shift' && !isShiftDown) {
        isShiftDown = true;
        map.setOptions({ draggable: false });
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === 'Shift') {
        isShiftDown = false;
        map.setOptions({ draggable: true });
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    map.setOptions({ 
      clickableIcons: false
    });

    const listener = map.addListener('click', (e) => {
      onClick(e);
    });

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
      if (listener) {
        listener.remove();
      }
    };
  }, [map, onClick]);

  return null;
};

export default MapController; 