export const CityWideIcon = ({ className, style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="currentColor"
      className={className}
      style={style}
    >
      <path
        d="M6.00001 0C4.54186 0.00177305 3.14394 0.581805 2.11288 1.61287C1.08181 2.64394 0.501779 4.04185 0.500006 5.5C0.498284 6.69163 0.887576 7.85092 1.60816 8.8C1.60816 8.8 1.75816 8.99725 1.78226 9.02585L6.00001 14L10.2195 9.0237C10.2417 8.99705 10.3919 8.7998 10.3919 8.7998L10.3926 8.7986C11.1128 7.84987 11.5018 6.69109 11.5 5.5C11.4982 4.04185 10.9182 2.64394 9.88713 1.61287C8.85607 0.581805 7.45815 0.00177305 6.00001 0ZM6.00001 7.5C5.60444 7.5 5.21776 7.3827 4.88887 7.16294C4.55997 6.94318 4.30362 6.63082 4.15225 6.26537C4.00087 5.89991 3.96126 5.49778 4.03843 5.10982C4.11561 4.72186 4.30609 4.36549 4.58579 4.08579C4.8655 3.80608 5.22186 3.6156 5.60983 3.53843C5.99779 3.46126 6.39992 3.50087 6.76537 3.65224C7.13082 3.80362 7.44318 4.05996 7.66294 4.38886C7.88271 4.71776 8.00001 5.10444 8.00001 5.5C7.99941 6.03025 7.78851 6.53861 7.41356 6.91356C7.03862 7.2885 6.53026 7.4994 6.00001 7.5Z"
        fill="currentColor"
      />
    </svg>
  );
  
  export const DistrictIcon = ({ className, style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      className={className}
      style={style}
    >
      <g clipPath="url(#clip0_382_409)">
        <path
          d="M14.6214 2.01495L10.6214 1.01495C10.5193 0.989618 10.4119 0.996874 10.3142 1.0357L5.4642 2.9757L1.62135 2.01495C1.54764 1.99651 1.47069 1.99511 1.39635 2.01087C1.32201 2.02662 1.25225 2.05911 1.19235 2.10586C1.13245 2.15262 1.08399 2.21241 1.05066 2.2807C1.01733 2.34898 1.00001 2.42397 1 2.49995V13.5C1.00001 13.6114 1.03728 13.7197 1.10589 13.8076C1.17449 13.8955 1.2705 13.9579 1.37865 13.985L5.37865 14.985C5.41836 14.9948 5.4591 14.9998 5.5 15C5.56366 15 5.62673 14.9878 5.6858 14.9641L10.5358 13.0241L14.3788 13.985C14.4525 14.0034 14.5294 14.0048 14.6038 13.989C14.6781 13.9733 14.7478 13.9408 14.8077 13.894C14.8676 13.8473 14.916 13.7875 14.9494 13.7192C14.9827 13.6509 15 13.5759 15 13.5V2.49995C15 2.38847 14.9627 2.28019 14.8941 2.19231C14.8255 2.10444 14.7295 2.04201 14.6214 2.01495ZM14 5.49995H11V2.14025L14 2.89025V5.49995ZM5 9.49995H2V6.49995H5V9.49995ZM6 5.49995V3.8385L10 2.2385V5.49995H6ZM10 6.49995V9.49995H6V6.49995H10ZM6 10.5H10V12.1613L6 13.7613V10.5ZM11 6.49995H14V9.49995H11V6.49995ZM5 3.89025V5.49995H2V3.14025L5 3.89025ZM2 10.5H5V13.8596L2 13.1096V10.5ZM11 12.1093V10.5H14V12.8596L11 12.1093Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_382_409">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  
