import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { rectanglesIntersect } from '../utils/markerUtils';
import {
  setInspectorVisible,
  setSelection,
  toggleSelection,
  setLocalDeviceMarkers,
  setLocalDistrictMarkers,
  removeMarkers,
  updateMarkerPosition,
  addNewMarker
} from '../../redux/markerMakerSlice';

const selectMarkerMaker = state => state.markerMaker;
const selectDevices = state => state.devices.devices;
const selectDistricts = state => state.districts.districts;

export const useMarkerManager = (mapRef, selectionBoxRef) => {
  const dispatch = useDispatch();
  const isShiftPressedRef = useRef(false);
  const isBoxSelectingRef = useRef(false);
  const dragStartRef = useRef({ x: 0, y: 0 });

  const { currentMarkerType, localDeviceMarkers, localDistrictMarkers } = useSelector(selectMarkerMaker);
  const deviceMarkers = useSelector(selectDevices);
  const districtMarkers = useSelector(selectDistricts);

  // Initialize markers in Redux store
  useEffect(() => {
    dispatch(setLocalDeviceMarkers(deviceMarkers));
  }, [deviceMarkers]); // Only run when deviceMarkers changes

  useEffect(() => {
    dispatch(setLocalDistrictMarkers(districtMarkers));
  }, [districtMarkers]); // Only run when districtMarkers changes

  // Selection Box Event Handlers
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Shift') isShiftPressedRef.current = true;
      if (e.key === 'Escape') {
        dispatch(setSelection([]));
        dispatch(setInspectorVisible(false));
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === 'Shift') isShiftPressedRef.current = false;
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [dispatch]); // Add dispatch to dependencies

  // Move event handlers outside of effects and memoize them
  const handleMouseDown = useCallback((e) => {
    if (!isShiftPressedRef.current || !mapRef.current) return;
    
    const rect = mapRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    
    isBoxSelectingRef.current = true;
    dragStartRef.current = { x, y };
    
    if (selectionBoxRef.current) {
      selectionBoxRef.current.style.display = 'block';
      selectionBoxRef.current.style.left = `${x}px`;
      selectionBoxRef.current.style.top = `${y}px`;
      selectionBoxRef.current.style.width = '0';
      selectionBoxRef.current.style.height = '0';
    }
  }, [mapRef, selectionBoxRef]);

  const handleMouseMove = useCallback((e) => {
    if (!isBoxSelectingRef.current || !selectionBoxRef.current || !mapRef.current) return;
    if (!isShiftPressedRef.current) {
      isBoxSelectingRef.current = false;
      if (selectionBoxRef.current) {
        selectionBoxRef.current.style.display = 'none';
      }
      return;
    }

    const rect = mapRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    // Calculate drag distance
    const dragDistance = Math.sqrt(
      Math.pow(x - dragStartRef.current.x, 2) + 
      Math.pow(y - dragStartRef.current.y, 2)
    );

    // Only show selection box if drag distance exceeds 5 pixels
    if (dragDistance < 5) {
      selectionBoxRef.current.style.display = 'none';
      return;
    }

    selectionBoxRef.current.style.display = 'block';
    const left = Math.min(x, dragStartRef.current.x);
    const top = Math.min(y, dragStartRef.current.y);
    const width = Math.abs(x - dragStartRef.current.x);
    const height = Math.abs(y - dragStartRef.current.y);

    selectionBoxRef.current.style.left = `${left}px`;
    selectionBoxRef.current.style.top = `${top}px`;
    selectionBoxRef.current.style.width = `${width}px`;
    selectionBoxRef.current.style.height = `${height}px`;
  }, [mapRef, selectionBoxRef]);

  const handleMouseUp = useCallback((e) => {
    if (!isBoxSelectingRef.current || !selectionBoxRef.current) return;
    isBoxSelectingRef.current = false;
    
    const box = selectionBoxRef.current.getBoundingClientRect();
    const markersInBox = [];

    const checkMarker = (marker, type) => {
      const markerElement = document.querySelector(
        `[data-marker-id="${type}-${marker.lat}-${marker.lng}"]`
      );
      if (markerElement) {
        const markerBox = markerElement.getBoundingClientRect();
        if (rectanglesIntersect(box, markerBox)) {
          markersInBox.push({
            id: type === 'device' ? marker.sensor_id : marker.id,
            type
          });
        }
      }
    };

    localDeviceMarkers.forEach(marker => checkMarker(marker, 'device'));
    localDistrictMarkers.forEach(marker => checkMarker(marker, 'district'));

    dispatch(toggleSelection(markersInBox.map(marker => marker.id)));
    
    if (markersInBox.length > 0) {
      dispatch(setInspectorVisible(true));
    }
    
    selectionBoxRef.current.style.display = 'none';
    selectionBoxRef.current.style.width = '0';
    selectionBoxRef.current.style.height = '0';
  }, [dispatch, localDeviceMarkers, localDistrictMarkers]);

  // Update the event listener effect to use memoized handlers and remove unnecessary dependencies
  useEffect(() => {
    if (!mapRef.current) return;

    const mapElement = mapRef.current;
    mapElement.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      mapElement.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseDown, handleMouseMove, handleMouseUp]); // Only depend on the memoized handlers

  // Memoize the entire handlers object
  const handlers = useMemo(() => ({
    handleMapClick: (e) => {
      console.log("map click");
      dispatch(addNewMarker({
        lat: Number(e.latLng.lat()),
        lng: Number(e.latLng.lng()),
        markerType: currentMarkerType
      }));
    },
    handleMarkerClick: (e, marker, isRightClick = false) => {
      if (isRightClick) {
        dispatch(removeMarkers(marker));
        return;
      }

      const markerId = marker.sensor_id || marker.id;
      
      if (!isShiftPressedRef.current) {
        dispatch(setSelection([markerId]));
      } else {
        dispatch(toggleSelection(markerId));
      }
      dispatch(setInspectorVisible(true));
    },
    handleMarkerDragEnd: (marker, latLng) => {
      dispatch(updateMarkerPosition({
        markerId: marker.sensor_id || marker.id,
        lat: Number(latLng.lat()),
        lng: Number(latLng.lng()),
        markerType: marker.sensor_id ? 'device' : 'district'
      }));
    }
  }), [currentMarkerType]); // Only depend on currentMarkerType

  return handlers;
}; 