import React from "react";

function StatusIndicator({ label, color }) {
  return (
    <div className="flex gap-2 px-4 py-2.5 whitespace-nowrap bg-white border border-solid border-slate-900 rounded-[30px]">
      <div className={`flex shrink-0 self-start w-4 h-4 ${color} rounded-full`} />
      <div className="basis-auto">{label}</div>
    </div>
  );
}

export default StatusIndicator;