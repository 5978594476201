import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const FilterWindow = ({ column, options, handleFilter, handleCheckboxFilter }) => {
  // Get current filters from Redux store
  const columnFilters = useSelector((state) => state.devicesList.columnFilters);
  
  // Add status value mapping
  const statusValueMap = {
    'Good': 1,
    'Urgent': 2,
    'Attention': 3
  };

  // Initialize checkbox state based on current filters
  const [checkedItems, setCheckedItems] = useState(
    options.reduce((acc, option) => ({
      ...acc,
      [option]: columnFilters[column]?.includes(column === 'status' ? statusValueMap[option] : option) || false
    }), {})
  );

  // Update local state when filters change externally
  useEffect(() => {
    setCheckedItems(
      options.reduce((acc, option) => ({
        ...acc,
        [option]: columnFilters[column]?.includes(column === 'status' ? statusValueMap[option] : option) || false
      }), {})
    );
  }, [column, options, columnFilters]);

  const handleChange = (option, checked) => {
    setCheckedItems(prev => ({
      ...prev,
      [option]: checked
    }));
    // Pass the numeric value for status filters
    const valueToFilter = column === 'status' ? statusValueMap[option] : option;
    handleCheckboxFilter(column, valueToFilter, checked);
  };

  return (
    <div 
      className="absolute z-20 mt-2 w-48 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 p-2"
      onClick={(e) => e.stopPropagation()}
    >
      {column === 'sensor_id' ? (
        <input
          type="text"
          className="w-full p-2 border rounded"
          placeholder="Filter sensor ID..."
          onChange={(e) => handleFilter(column, e.target.value)}
          value={columnFilters[column] || ''}
          autoFocus
        />
      ) : (
        <div className="space-y-2">
          {options.map((option) => (
            <label key={option} className="flex items-center">
              <input
                type="checkbox"
                className="mr-2"
                checked={checkedItems[option] || false}
                onChange={(e) => handleChange(option, e.target.checked)}
              />
              {option}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(FilterWindow); 