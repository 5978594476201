import React from 'react';

const RegularButton = ({
  text,
  colorScheme = 'dark',
  selected = false,
  onClick,
  className = '',
}) => {
  const baseButtonClass =
    'flex items-center justify-center text-lg px-1 py-2 rounded-full transition duration-800 border w-full';

  let buttonClass = '';

  if (colorScheme === 'dark') {
    if (selected) {
      // Selected: Yellow background and border, blue text
      buttonClass = 'bg-yellow border-yellow text-dark-blue';
    } else {
      // Default: Blue background, white border and text
      // Hover when default: Blue background, yellow border and text
      buttonClass =
        'bg-dark-blue border-white text-white hover:border-yellow hover:text-yellow';
    }
  } else if (colorScheme === 'light') {
    if (selected) {
      // Selected: Yellow background, blue border and text
      buttonClass = 'bg-yellow border-dark-blue text-dark-blue';
    } else {
      // Default: White background, blue border and text
      // Hover when default: White background, yellow border and text
      buttonClass =
        'bg-white border-dark-blue text-dark-blue hover:border-yellow hover:text-yellow';
    }
  }

  return (
    <button
      className={`${baseButtonClass} ${buttonClass} ${className}`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default RegularButton;
