import React from 'react';

const LastPicture = ({ date, time, className = "" }) => (
  <div className={`flex gap-5 justify-between p-2 w-full text-base text-white rounded-none bg-slate-700 bg-opacity-80 ${className}`}>
    <div>Last picture:</div>
    <div className="flex gap-1.5 whitespace-nowrap">
      <div className="grow">{date}</div>
      <div>⋅</div>
      <div>{time}</div>
    </div>
  </div>
);

export default LastPicture;