import devicesDubai from './dubai_with_readings_curated.json';
import { setDistricts } from '../redux/districtsSlice';
import { setDevices } from '../redux/devicesSlice';
import { setImages } from '../redux/imagesSlice';
import { setLocalDeviceMarkers, setLocalDistrictMarkers } from '../redux/markerMakerSlice';
import { store } from '../redux/store';
import { setMapSettings } from '../redux/settingsSlice';


const assignDistrictNames = (devices, districts) => {
    return devices.map(device => {
      const district = districts.find(d => d.id === device.district);
      return {
        ...device,
        district_name: district ? district.name : 'Unknown District' // Assign district name or 'Unknown' if no match
      };
    });
  };

const addMarkerProp = (devices) => {
  return devices.map(device => {
    return {
      ...device,
      marker: null,
    };
  });
};
  
  const devicesUpdated = {
    ...devicesDubai,
    devices: 
      addMarkerProp(assignDistrictNames(devicesDubai.devices, devicesDubai.districts))
  };
  
  const dispatchAllData = (data) => {
    // Update main stores
    store.dispatch(setDevices(data.devices));
    store.dispatch(setDistricts(data.districts));
    store.dispatch(setImages(data.images));
    if (data.settings) {
      store.dispatch(setMapSettings(data.settings));
    }
    
    // Update local copies
    store.dispatch(setLocalDeviceMarkers(data.devices));
    store.dispatch(setLocalDistrictMarkers(data.districts));
  };

const updateStoreWithJson = () => {
  dispatchAllData({
    devices: devicesUpdated.devices,
    districts: devicesUpdated.districts,
    images: devicesUpdated.images
  });
};

export const downloadJson = () => {
  const state = store.getState();
  const jsonData = {
    devices: state.devices.devices,
    districts: state.districts.districts,
    images: state.images,
    settings: state.settings
  };
  
  const blob = new Blob([JSON.stringify(jsonData, null, 2)], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'map_data.json';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const uploadJson = async (file) => {
  try {
    const text = await file.text();
    const json = JSON.parse(text);
    dispatchAllData(json);
    return true;
  } catch (error) {
    console.error('Error uploading JSON:', error);
    return false;
  }
};

export default updateStoreWithJson;
