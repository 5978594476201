import { useState } from 'react';
import { useSelector } from 'react-redux';
import './ImageSelector.css';

const ImageSelector = ({ 
  currentImage, 
  onImageSelect, 
  placeholder = "No image",
  markerType = 'device'
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const availableImages = useSelector(state => state.images[markerType === 'device' ? 'devices' : 'districts']);

  const getImagePath = (image) => `/images/${markerType === 'device' ? 'sensor' : 'districts'}/${image}`;

  return (
    <>
      <div 
        className="image-preview"
        onClick={() => setIsModalOpen(true)}
        role="button"
        tabIndex={0}
      >
        {currentImage ? (
          <img src={getImagePath(currentImage)} alt="Selected" />
        ) : (
          <div className="placeholder-text">{placeholder}</div>
        )}
      </div>

      {isModalOpen && (
        <div className="image-modal-overlay" onClick={() => setIsModalOpen(false)}>
          <div className="image-modal" onClick={e => e.stopPropagation()}>
            <div className="image-grid">
              {availableImages.map((image, index) => (
                <img
                  key={index}
                  src={getImagePath(image)}
                  alt={`Option ${index + 1}`}
                  onClick={() => {
                    onImageSelect(image);
                    setIsModalOpen(false);
                  }}
                  className={currentImage === image ? 'selected' : ''}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageSelector; 