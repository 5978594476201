import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  center: { lat: 25.077174, lng: 55.190888 },
  zoom: 13,
  mapId: '9cc7d3e49ca28f32'
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setMapSettings: (state, action) => {
      return { ...state, ...action.payload };
    }
  }
});

export const { setMapSettings } = settingsSlice.actions;
export default settingsSlice.reducer; 