import React from "react";
import { useDispatch } from "react-redux";
import { setInspectedDevice, setZoomedDevices } from "../../../redux/mapStateSlice";

function DeviceItem({ sensor_id, location, status, lat, lng }) {
    const dispatch = useDispatch();
    const type = "Main-culvert";
    const imageSrc = "arrow.svg";

    const getStatusInfo = (status) => {
        switch(status) {
            case 1: return { text: "Good", colorClass: "bg-status-success" };
            case 2: return { text: "Urgent", colorClass: "bg-status-warning" };
            case 3: return { text: "Attention", colorClass: "bg-status-error" };
            default: return { text: "Unknown", colorClass: "bg-status-default" };
        }
    }

    const onInspect = () => {
        dispatch(setInspectedDevice(sensor_id));
    }

    const zoomToDevice = () => {
      dispatch(setZoomedDevices([
        {sensor_id, lat: lat + 0.002, lng: lng + 0.002}, 
        {sensor_id, lat: lat - 0.002, lng: lng - 0.002}, 
        {sensor_id, lat, lng}
      ]));
    }
 
    const { text: statusText, colorClass } = getStatusInfo(status);

    return (
      <tr className="hover:bg-yellow-tint cursor-pointer" onClick={zoomToDevice}>
        <td className="p-2 text-center">
          <img 
            loading="lazy" 
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/585fb0d31ab0a9f7ab219f2baeac445bbacf6857a84cbd17695b612d559ac487?placeholderIfAbsent=true&apiKey=89cf183a92e74cf6ac32b5c47e264d6f" 
            alt="" 
            className="object-contain w-8 h-8 mx-auto" 
          />
        </td>
        <td className="p-2">{sensor_id}</td>
        <td className="p-2">{location}</td>
        <td className="p-2">{type}</td>
        <td className="p-2">
          <div className="flex items-center">
            <div className={`w-3.5 h-3.5 rounded-full ${colorClass}`} />
            <span className="ml-2">{statusText}</span>
          </div>
        </td>
        <td className="p-2">
          <img 
            loading="lazy" 
            src={imageSrc} 
            onClick={onInspect} 
            alt="" 
            className="object-contain w-8 h-8 cursor-pointer" 
          />
        </td>
      </tr>
    );
}

export default DeviceItem;