export const clusterRenderer = {
    render: (cluster, stats, map) => {
        const contentElement = (cluster) => {

            const content = document.createElement('div');
            const count = document.createElement('span');
            const contentContainer = document.createElement('div');

            content.classList.add('cluster-pin')
            count.classList.add('cluster-count')
            contentContainer.classList.add('cluster-pin-content')

            content.appendChild(count);
            contentContainer.appendChild(content);

            count.innerHTML = String(cluster.markers.length);

            return contentContainer;
        };

        return new window.google.maps.marker.AdvancedMarkerElement({ 
            position: cluster.position,
            content: contentElement(cluster)
        })
    }
};