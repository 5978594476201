import React from 'react'
import { getStatusColor } from '../../utils/status_color'
import { Pin } from '@vis.gl/react-google-maps'

const MarkerPinSelected = React.memo(({device}) => {
  return (
    <div>
        <Pin
            background={'#051A36'}
            glyphColor={getStatusColor(device.status)}
            borderColor={getStatusColor(device.status)}
            scale={1.5}
        />      
    </div>
  )
});

export default MarkerPinSelected
