import { useEffect } from "react";
import { Pin, useMap } from "@vis.gl/react-google-maps";
import { useSelector, useDispatch } from "react-redux";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import DistrictCard from "./district_card/DistrictCard";
import { displayDevices, selectDistrict } from '../../redux/mapStateSlice';

export const DistrictMarkers = () => {

    const displayedDistricts = useSelector((state) => state.mapState.displayedDistricts);
    const devices = useSelector(state => state.devices.devices);
    const dispatch = useDispatch();

    const handleDistrictClick = (e, district) => {
        const districtDevices = devices.filter(device => device.district === district.id);
        dispatch(selectDistrict(district.id));
        dispatch(displayDevices(districtDevices));
    };

    return (
        <>
            {displayedDistricts && displayedDistricts.map(
                (district) => (
                    <AdvancedMarker 
                        key={district.id}
                        position={{ lat: district.lat, lng: district.lng }}
                        clickable={true}
                        onClick={(e) => {
                            e.stop();
                        }}
                    >
                        <DistrictCard 
                            district={district}
                            onClick={handleDistrictClick}
                        />
                    </AdvancedMarker>
                )
            )}
        </>
    )
};
