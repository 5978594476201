import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { Text } from 'recharts';
import { useDispatch } from 'react-redux';
import { setSelectedMeasurementIndex } from '../../redux/mapStateSlice';

const AIHistoryChart = ({ measurements = [] }) => {
  const dispatch = useDispatch();
  
  if (!measurements.length) {
    return null;
  }
  
  const data = measurements.map((m, index) => ({
    date: m.date.split('/').slice(1).join('/'),
    score: parseInt(m.ai_score),
    fill: parseInt(m.ai_score) >= 80 ? '#FF0000' : 
          parseInt(m.ai_score) >= 50 ? '#FFD700' : '#00FF00',
    index
  }));

  const minScore = Math.min(...data.map(d => d.score));
  const maxScore = Math.max(...data.map(d => d.score));

  return (
    <div className="w-full h-32 bg-dark-blue mt-4">
      <ResponsiveContainer width="100%" height="100%" className="relative">
        <div id="title">
          <span className="absolute text-white" style={{top: 5, left: 10, fontSize: '10px'}}>
            AI Priority Score History
          </span>
        </div>
        <LineChart 
          data={data} 
          margin={{ top: 25, right: 20, left: -38, bottom: 0 }}
          onMouseMove={(e) => {
            if (e.activeTooltipIndex !== undefined) {
              dispatch(setSelectedMeasurementIndex(e.activeTooltipIndex));
            }
          }}
          onMouseLeave={() => {
            dispatch(setSelectedMeasurementIndex(null));
          }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="white" />
          <XAxis dataKey="date" stroke="white" fontSize={10} />
          <YAxis stroke="white" tick={false} domain={[minScore, maxScore]} />
          <Tooltip contentStyle={{ backgroundColor: '#051A36', borderColor: 'white', fontSize: 12, color: 'white' }} itemStyle={{ color: 'white' }} />
          <Line 
            type="monotone" 
            dataKey="score" 
            stroke="none"
            dot={(props) => {
              const { cx, cy, payload } = props;
              return <circle 
                key={`dot-${payload.index}`} 
                cx={cx} 
                cy={cy} 
                r={4} 
                fill={payload.fill} 
              />;
            }}
            isAnimationActive={false}
            strokeWidth={0}
            activeDot={{ 
              r: 6,
              stroke: 'none',
              fill: (data) => data.fill
            }}
          />
          {minScore <= 80 && maxScore >= 80 && (
            <ReferenceLine 
              y={80} 
              stroke="#FF0000" 
              strokeDasharray="3 3"
              label={{
                value: "80",
                position: "right",
                fill: "#FF0000",
                fontSize: 10
              }}
            />
          )}
          {minScore <= 50 && maxScore >= 50 && (
            <ReferenceLine 
              y={50} 
              stroke="#FFD700" 
              strokeDasharray="3 3"
              label={{
                value: "50",
                position: "right",
                fill: "#FFD700",
                fontSize: 10
              }}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AIHistoryChart;
