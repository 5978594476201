import { memo } from 'react';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import DistrictCard from '../../interactive_map/new_map/district_card/DistrictCard';
import { useState } from 'react';

const CustomMarker = memo(({ lat, lng, isSelected, onLeftClick, onRightClick, onDragEnd, type, marker, color }) => {
  const [isMarkerDragging, setIsMarkerDragging] = useState(false);
  const [recentDragEnd, setRecentDragEnd] = useState(false);

  const handleDragStart = (e) => {
    console.log("Dragging started");
    setIsMarkerDragging(true);
  };

  const handleDragEnd = (e) => {
    if (isMarkerDragging) {
      onDragEnd(e.latLng);
      setRecentDragEnd(true);
      setTimeout(() => {
        setIsMarkerDragging(false);
        setRecentDragEnd(false);
      }, 200);
    }
  };

  const handleClick = (e, payload) => {
    e.stopPropagation();
    e.preventDefault();
    if (!isMarkerDragging && !recentDragEnd) {
      e.preventDefault();
      e.stopPropagation();
      onLeftClick(e);
    }
  };

  if (type === 'district') {
    return (
      <AdvancedMarker 
        position={{ lat, lng }}
        clickable={true}
        draggable={true}
        onDragStart={handleDragStart}
        onDragEnd={(e) => handleDragEnd(e)}
      >
        <div data-marker-id={`${type}-${lat}-${lng}`}>
          <DistrictCard 
            district={{
              id: marker.id || 'temp-id',
              lat: lat,
              lng: lng,
              image: marker.image || 'default.png'
            }}
            onClick={handleClick}
            onRightClick={() => onRightClick(marker)}
            isSelected={isSelected}
            color={color}
            useLocalData={true}
          />
        </div>
      </AdvancedMarker>
    );
  }

  return (
    <AdvancedMarker 
      position={{ lat, lng }}
      clickable={true}
      draggable={true}
      onDragStart={handleDragStart}
      onDragEnd={(e) => handleDragEnd(e)}
    >
      <div 
        className={`custom-marker ${isSelected ? 'selected' : ''}`}
        data-marker-id={`${type}-${lat}-${lng}`}
        onClick={handleClick}
        onContextMenu={(e) => {
          e.preventDefault();
          onRightClick();
        }}
        onMouseDown={(e) => e.preventDefault()}
        onDragStart={(e) => e.preventDefault()}
        style={{ 
          userSelect: 'none',
          backgroundColor: color,
          border: isSelected ? '3px solid #FFD700' : 'none',
          boxShadow: isSelected ? '0 0 2px 1px rgba(0,0,0,0.3)' : 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          fontWeight: 'bold',
          fontSize: '14px'
        }}
      >
        {type === 'device' && marker.status}
      </div>
    </AdvancedMarker>
  );
}, (prevProps, nextProps) => {
  return (
    prevProps.lat === nextProps.lat &&
    prevProps.lng === nextProps.lng &&
    prevProps.isSelected === nextProps.isSelected &&
    prevProps.type === nextProps.type &&
    JSON.stringify(prevProps.marker) === JSON.stringify(nextProps.marker)
  );
});

export default CustomMarker; 