import React, { useState, useEffect } from 'react';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/solid';

const DefaultIcon = ({ className, color, direction = 'right' }) => (
  <span className={className} style={{ color, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    {direction === 'left' ? <ChevronLeftIcon className="w-6 h-6" /> : <ChevronRightIcon className="w-6 h-6" />}
  </span>
);

const IconCircleButton = ({
  text,
  icon: Icon,
  circlePosition = 'right',
  colorScheme = 'dark',
  selected = false,
  onClick,
  className = '',
  fontSize = '1.125rem',
  circleColor,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [styles, setStyles] = useState({});

  useEffect(() => {
    const baseButtonStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontWeight: '500',
      fontSize: fontSize,
      padding: '0.25rem',
      borderRadius: '9999px',
      borderWidth: '1px',
      width: '100%',
      whiteSpace: 'nowrap', // Prevent text from wrapping
    };

    const circleStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '2.5rem',
      height: '2.5rem',
      borderRadius: '9999px',
    };

    let buttonStyle = { ...baseButtonStyle };
    let iconStyle = { color: '' };
    let circleBackgroundColor = '';

    if (colorScheme === 'dark') {
      if (selected) {
        buttonStyle.backgroundColor = '#FFFA08';
        buttonStyle.borderColor = '#FFFA08';
        buttonStyle.color = '#051A36';
        circleBackgroundColor = circleColor || '#FFFA08';
        iconStyle.color = '#051A36';
      } else {
        buttonStyle.backgroundColor = '#051A36';
        buttonStyle.borderColor = 'white';
        buttonStyle.color = 'white';
        circleBackgroundColor = circleColor || (isHovered ? 'white' : '#FFFA08');
        iconStyle.color = isHovered ? '#051A36' : 'white';
      }
    } else if (colorScheme === 'light') {
      if (selected) {
        buttonStyle.backgroundColor = '#051A36';
        buttonStyle.borderColor = '#051A36';
        buttonStyle.color = 'white';
        circleBackgroundColor = circleColor || '#051A36';
        iconStyle.color = '#FFFA08';
      } else {
        buttonStyle.backgroundColor = 'white';
        buttonStyle.borderColor = '#051A36';
        buttonStyle.color = '#051A36';
        circleBackgroundColor = circleColor || (isHovered ? '#051A36' : '#FFFA08');
        iconStyle.color = isHovered ? 'white' : '#051A36';
      }
    }

    setStyles({
      button: buttonStyle,
      circle: { ...circleStyle, backgroundColor: circleBackgroundColor },
      icon: iconStyle,
    });
  }, [colorScheme, selected, isHovered, fontSize, circleColor]);

  const IconComponent = Icon || DefaultIcon;

  return (
    <button
      style={styles.button}
      className={className}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {circlePosition === 'left' && (
        <span style={{ ...styles.circle, transition: isHovered ? 'all 800ms' : 'none' }}>
          <div style={{ ...styles.icon, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <IconComponent style={styles.icon} className="w-6 h-6" direction="left" />
          </div>
        </span>
      )}
      <span className="mx-2 flex-grow text-left">{text}</span>
      {circlePosition === 'right' && (
        <span style={{ ...styles.circle, transition: isHovered ? 'all 800ms' : 'none' }}>
          <div style={{ ...styles.icon, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <IconComponent style={styles.icon} className="w-6 h-6" />
          </div>
        </span>
      )}
    </button>
  );
};

export default IconCircleButton;