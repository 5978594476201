import React from 'react';
import IconCircleBox from '../../components/icon-box';

function MaintenanceInfo({ label, text, status }) {
  const getStatusClass = (status) => {
    switch (status) {
      case 1: return 'bg-status-success';
      case 2: return 'bg-status-warning';
      case 3: return 'bg-status-error';
      default: return 'bg-status-default';
    }
  };

  return (
    <div className="flex gap-5 mt-3">
      <div className="my-auto font-bold text-stone-900">
        {label}:
      </div>
      <div className="flex-grow">
        <IconCircleBox
          text={text}
          colorScheme="light"
          circlePosition="left"
          circleColor={getStatusClass(status)}
          className="w-full"
        />
      </div>
    </div>
  );
}

export default MaintenanceInfo;