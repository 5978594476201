import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useStore } from 'react-redux';
import { setInspectedDevice } from '../../redux/mapStateSlice';
import IconCircleButton from '../../components/icon-button';
import { getStatusColor } from '../../utils/status_color';

const DeviceCard = ({device}) => {

  const [isHovered, setIsHovered] = useState(false);
  const store = useStore();

  if (!device) return null;
  // Prevent the map from catching clicks on the card
  const handleCardClick = (e) => {
    e.stopPropagation();
  };

  const handleInspect = (e) => {
    e.stopPropagation();
    store.dispatch(setInspectedDevice(device.sensor_id));
  }

  // Function to get the status text and color
  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return { text: 'Good', color: { backgroundColor: getStatusColor(1) } };
      case 2:
        return { text: 'Urgent', color: { backgroundColor: getStatusColor(2) } };
      case 3:
        return { text: 'Attention', color: { backgroundColor: getStatusColor(3) } };
      default:
        return { text: 'Unknown', color: { backgroundColor: getStatusColor(0) } };
    }
  };

  const { text: statusText, color: statusColor } = getStatusText(device.status);

  return (
    // style={styles.cardContainer} is the style for the card container
    <div className="min-w-[200px]" onClick={handleCardClick}> 
      <div style={styles.imageContainer}>
        <img 
          src="walterodevice.png" 
          alt="Waltero device" 
          style={styles.image} 
        />
      </div>
      <div style={styles.details}>
        <div style={styles.detailRow}>
          <strong>ID:</strong>
          <span>{device.sensor_id}</span>
        </div>
        <div style={styles.detailRow}>
          <strong>Status:</strong>
          <span style={styles.status}>
            <span style={{ ...styles.statusDot, ...statusColor }}></span> {statusText}
          </span>
        </div>
      </div>
      <IconCircleButton 
        text="Inspect"
        icon={null}
        colorScheme="light"
        onClick={handleInspect}
        fontSize="1rem"
        circleColor={getStatusColor(device.status)}
        className="w-full"
      />
      {/* <div style={styles.arrow}></div> */}
    </div>
  );
};

const styles = {
  cardContainer: {
    border: '1px solid #dcdcdc',
    borderRadius: '10px',
    padding: '20px',
    width: '200px',
    textAlign: 'center',
    position: 'relative',
    bottom: '20px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    fontFamily: 'Arial, sans-serif',
    zIndex: '1000',
    pointerEvents: 'all',
  },
  name: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '15px',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '15px',
  },
  image: {
    width: "auto",
    height: '60px',
  },
  details: {
    fontSize: '15px',
    marginBottom: '15px',
  },
  detailRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '5px',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    marginLeft: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  statusDot: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    marginRight: '5px',
  },
  inspectButton: {
    width: '100%',
    padding: '10px',
    borderRadius: '20px',
    border: '1px solid #dcdcdc',
    backgroundColor: '#f9f9f9',
    cursor: 'pointer',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.3s ease', // Smooth transition for hover effect
  },
  buttonDot: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    marginRight: '5px',
  },
  arrow: {
    width: '0',
    height: '0',
    borderLeft: '20px solid transparent',
    borderRight: '20px solid transparent',
    borderTop: '20px solid #fff',
    position: 'absolute',
    bottom: '0px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
};

export default DeviceCard;
