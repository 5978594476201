import React from 'react';
import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StatusGraph = ({ className }) => {
  // Updated selector to access nested devices array
  const devices = useSelector((state) => state.devices.devices || []);

  // Function to process AI scores
  const processAiScores = (devices) => {
    const ranges = {
      '00-10': 0,
      '10-20': 0,
      '20-30': 0,
      '30-40': 0,
      '40-50': 0,
      '50-60': 0,
      '60-70': 0,
      '70-80': 0,
      '80-90': 0,
      '90-100': 0,
    };

    devices.forEach((device) => {
      const latestMeasure = device.measures?.reduce((latest, current) => {
        return new Date(current.date) > new Date(latest.date) ? current : latest;
      }, device.measures[0]);

      if (latestMeasure && latestMeasure.ai_score) {
        const aiScore = parseInt(latestMeasure.ai_score, 10);
        if (aiScore >= 0 && aiScore <= 10) ranges['00-10']++;
        else if (aiScore > 10 && aiScore <= 20) ranges['10-20']++;
        else if (aiScore > 20 && aiScore <= 30) ranges['20-30']++;
        else if (aiScore > 30 && aiScore <= 40) ranges['30-40']++;
        else if (aiScore > 40 && aiScore <= 50) ranges['40-50']++;
        else if (aiScore > 50 && aiScore <= 60) ranges['50-60']++;
        else if (aiScore > 60 && aiScore <= 70) ranges['60-70']++;
        else if (aiScore > 70 && aiScore <= 80) ranges['70-80']++;
        else if (aiScore > 80 && aiScore <= 90) ranges['80-90']++;
        else if (aiScore > 90 && aiScore <= 100) ranges['90-100']++;
      }
    });

    return ranges;
  };

  const aiScoreRanges = processAiScores(devices);

  // Prepare data for the chart
  const data = {
    labels: Object.keys(aiScoreRanges),
    datasets: [
      {
        label: 'AI prioritization scores',
        data: Object.values(aiScoreRanges),
        backgroundColor: [
          '#34D399', // green for lower scores
          '#34D399',
          '#34D399',
          '#FBBF24', // yellow for mid scores
          '#FBBF24',
          '#FBBF24',
          '#F87171', // red for higher scores
          '#F87171',
          '#F87171',
          '#F87171',
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 10
          }
        }
      },
      x: {
        ticks: {
          font: {
            size: 10
          }
        }
      }
    }
  };

  return (
    <div className={className}>
      <h2 style={{ fontSize: '14px', marginBottom: '8px' }}>AI prioritization scores</h2>
      <Bar data={data} options={options} />
    </div>
  );
};

export default StatusGraph;
