import React from 'react';
import StatusIndicatorGroup from './StatusIndicatorGroup';
import { useSelector } from 'react-redux';

const DistrictCard = React.memo(({district, onClick, onRightClick, isSelected, useLocalData = false}) => {
  const devices = useSelector(state => useLocalData ? state.markerMaker.localDeviceMarkers : state.devices.devices);
  const districtDevices = devices.filter(device => device.district === district.id);

  const statusCounts = {
    status1: districtDevices.filter(device => device.status === 1).length,
    status2: districtDevices.filter(device => device.status === 2).length,
    status3: districtDevices.filter(device => device.status === 3).length,
  };

  const handleClick = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    onClick(e, district);
  };

  const handleRightClick = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    onRightClick?.(district);
  };

  return (
    <div
      onClick={handleClick}
      onContextMenu={handleRightClick}
      style={{ 
        cursor: 'pointer',
        border: isSelected ? '2px solid blue' : 'none',
        borderRadius: '8px'
      }}
      data-marker-id={`district-${district.lat}-${district.lng}`}
    >
      <section className="flex flex-col text-base leading-tight text-white whitespace-nowrap rounded-none max-w-[120px]">
        <div className="flex relative flex-col px-2 pt-2 pb-5 w-full rounded aspect-[0.882]">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/581acaed5feced0344b1f756d4b825b5ff40296762e83fb4b182b890b9f9b70a?placeholderIfAbsent=true&apiKey=89cf183a92e74cf6ac32b5c47e264d6f"
            alt=""
            className="object-cover absolute inset-0 size-full"
          />
          <img
            loading="lazy"
            src={`/images/districts/${district.image}`}
            alt=""
            className="object-contain w-full aspect-[1.18] z-10"
          />
          <StatusIndicatorGroup statusCounts={statusCounts} />
        </div>
      </section>
    </div>
  );
});

export default DistrictCard;