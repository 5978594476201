import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  expandedDevice: null,
  sortConfig: { key: 'sensor_id', direction: 'asc' },
  columnFilters: {},
  activeFilter: null,
};

const devicesListSlice = createSlice({
  name: 'devicesList',
  initialState,
  reducers: {
    setExpandedDevice: (state, action) => {
      state.expandedDevice = action.payload === state.expandedDevice ? null : action.payload;
    },
    setSortConfig: (state, action) => {
      const { key } = action.payload;
      let direction = 'asc';
      if (state.sortConfig.key === key && state.sortConfig.direction === 'asc') {
        direction = 'desc';
      }
      state.sortConfig = { key, direction };
    },
    setColumnFilter: (state, action) => {
      const { column, value } = action.payload;
      if (!value || value === '') {
        const { [column]: removed, ...rest } = state.columnFilters;
        state.columnFilters = rest;
      } else {
        state.columnFilters = {
          ...state.columnFilters,
          [column]: value
        };
      }
    },
    setCheckboxFilter: (state, action) => {
      const { column, value, checked } = action.payload;
      const currentFilters = state.columnFilters[column] || [];
      const newFilters = checked
        ? [...currentFilters, value]
        : currentFilters.filter(v => v !== value);
      
      if (newFilters.length === 0) {
        const { [column]: removed, ...rest } = state.columnFilters;
        state.columnFilters = rest;
      } else {
        state.columnFilters = {
          ...state.columnFilters,
          [column]: newFilters
        };
      }
    },
    setActiveFilter: (state, action) => {
      state.activeFilter = action.payload;
    },
  },
});

export const {
  setExpandedDevice,
  setSortConfig,
  setColumnFilter,
  setCheckboxFilter,
  setActiveFilter,
} = devicesListSlice.actions;

export const selectFilteredAndSortedDevices = createSelector(
  [(state) => state.devices.devices, 
   (state) => state.devicesList.columnFilters,
   (state) => state.devicesList.sortConfig],
  (devices, columnFilters, sortConfig) => {
    let result = [...devices];

    // Apply filters
    if (columnFilters && Object.keys(columnFilters).length > 0) {
      Object.entries(columnFilters).forEach(([column, filterValue]) => {
        if (Array.isArray(filterValue)) {
          if (filterValue.length > 0) {
            result = result.filter(device => filterValue.includes(device[column]));
          }
        } else if (filterValue && filterValue.toString().trim() !== '') {
          result = result.filter(device => 
            device[column]?.toString().toLowerCase().includes(filterValue.toLowerCase())
          );
        }
      });
    }

    // Apply sorting
    if (sortConfig.key) {
      result.sort((a, b) => {
        const aValue = a[sortConfig.key] || '';
        const bValue = b[sortConfig.key] || '';
        if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
    }
    return result;
  }
);

export default devicesListSlice.reducer; 