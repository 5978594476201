import {useMap} from '@vis.gl/react-google-maps';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { MarkerClusterer} from '@googlemaps/markerclusterer';
import { clusterRenderer } from './cluster_renderer';
import DeviceMarker from './device_marker';
import { useSelector } from 'react-redux';
import './cluster_pin.css';

export const ClusteredDeviceMarkers = () => {
  const [markers, setMarkers] = useState({});
  const devices = useSelector((state) => state.mapState.displayedDevices);

  const inspectedDeviceId = useSelector((state) => state.mapState.inspectedDeviceId);
  const selectedDeviceId = useSelector((state) => state.mapState.selectedDeviceId);
  const map = useMap();

  const clusterer = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({
        map,
        algorithmOptions: { maxZoom: 15, radius: 120 },
        renderer: clusterRenderer,
    });
  }, [map]);

  useEffect(() => {
      if (!clusterer) return;

      clusterer.clearMarkers();
      clusterer.addMarkers(Object.values(markers)); 
  }, [clusterer, markers]);

  // this callback will effectively get passsed as ref to the markers to keep
  // tracks of markers currently on the map
  const setMarkerRef = useCallback((marker, key) => {
    setMarkers(markers => {
      if ((marker && markers[key]) || (!marker && !markers[key]))
        return markers;

      if (marker) {
        return {...markers, [key]: marker};
      } else {
        const {[key]: _, ...newMarkers} = markers;

        return newMarkers;
      }
    });
  }, []);

  return (
    <>
        {devices.map(device => (
            <DeviceMarker
              device={device}
              key={device.sensor_id}
              setMarkerRef={setMarkerRef}
              isInspected={inspectedDeviceId === device.sensor_id}
              isSelected={selectedDeviceId === device.sensor_id}
            />
        ))}
    </>
);

};
