import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFilteredAndSortedDevices } from '../redux/devicesListSlice';
import DeviceInformation from '../device_cards/ai_card/DeviceInformation';
import DeviceTableRow from './DeviceTableRow';
import { setExpandedDevice } from '../redux/devicesListSlice';

const DevicesTableBody = () => {
  const dispatch = useDispatch();
  const expandedDevice = useSelector((state) => state.devicesList.expandedDevice);
  const filteredAndSortedDevices = useSelector(selectFilteredAndSortedDevices);

  const getUpcomingMaintenances = useCallback((device) => {
    if (!device.maintenances || device.maintenances.length === 0) return '-';
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const upcomingMaintenance = device.maintenances
      .filter(m => {
        const maintenanceDate = new Date(m.date);
        maintenanceDate.setHours(0, 0, 0, 0);
        return maintenanceDate >= today;
      })
      .map(m => m.task)
      .join(', ');
    
    return upcomingMaintenance || '-';
  }, []);

  const getStatusColor = useCallback((status) => {
    switch (status) {
      case 1: return 'bg-status-success';
      case 2: return 'bg-status-warning';
      case 3: return 'bg-status-error';
      default: return 'bg-status-default';
    }
  }, []);

  const getStatusText = useCallback((status) => {
    switch (status) {
      case 1: return 'Good';
      case 2: return 'Urgent';
      case 3: return 'Attention';
      default: return 'NA';
    }
  }, []);

  const handleExpandDevice = useCallback((deviceId) => {
    dispatch(setExpandedDevice(expandedDevice === deviceId ? null : deviceId));
  }, [dispatch, expandedDevice]);

  return (
    <tbody className="bg-white relative divide-y divide-gray-200">
      {filteredAndSortedDevices.map((device) => (
        <DeviceTableRow
          key={device.sensor_id}
          device={device}
          isExpanded={expandedDevice === device.sensor_id}
          onExpandClick={handleExpandDevice}
          getUpcomingMaintenances={getUpcomingMaintenances}
          getStatusColor={getStatusColor}
          getStatusText={getStatusText}
        />
      ))}
    </tbody>
  );
};

export default React.memo(DevicesTableBody); 