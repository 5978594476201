import React from "react";

function ImageViewer() {
  return (
    <div className="flex flex-col w-[76%] max-md:ml-0 max-md:w-full">
      <div className="flex relative flex-col grow text-base leading-tight text-white rounded min-h-[549px] max-md:mt-4 max-md:max-w-full">
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/40019b9d414f605eddaab454e96243cfdc164e24481c4df9d06a3571ce3922e9?placeholderIfAbsent=true&apiKey=89cf183a92e74cf6ac32b5c47e264d6f" alt="Sensor view" className="object-cover absolute inset-0 size-full" />
        <div className="flex relative flex-col pb-6 w-full rounded border border-white border-solid max-md:max-w-full">
          <div className="flex flex-wrap gap-5 justify-between p-2 w-full rounded-none bg-slate-700 bg-opacity-30 max-md:max-w-full">
            <div>Last picture:</div>
            <div className="flex gap-1.5 whitespace-nowrap">
              <div className="grow">2024/04/12</div>
              <div>⋅</div>
              <div className="text-right">10:00AM</div>
            </div>
          </div>
          <div className="flex flex-wrap gap-5 justify-between mx-6 mt-96 max-md:mt-10 max-md:mr-2.5 max-md:max-w-full">
            <div className="flex gap-6">
              <button className="flex gap-2 px-2 py-0.5 border border-white border-solid bg-slate-900 rounded-[30px]">
                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/128e64272326db3db96e4865d5f61adf53bf4ac85807e6acb2a0a1a720ffa2e8?placeholderIfAbsent=true&apiKey=89cf183a92e74cf6ac32b5c47e264d6f" alt="" className="object-contain shrink-0 w-8 aspect-square" />
                <span className="my-auto basis-auto">Take new picture</span>
              </button>
              <button>
                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/bc71775bd0bca578d4d79427973b6b8c3f56cf72492cc0bb67a3adc374fc86c7?placeholderIfAbsent=true&apiKey=89cf183a92e74cf6ac32b5c47e264d6f" alt="Action button" className="object-contain shrink-0 self-start mt-1 w-8 aspect-square" />
              </button>
            </div>
            <button>
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/8bfab9ef715477a0a34b9c01e163f5de1c11302b8e7b3b98ae91082e2dd7e5eb?placeholderIfAbsent=true&apiKey=89cf183a92e74cf6ac32b5c47e264d6f" alt="Action button" className="object-contain shrink-0 self-start mt-1 w-8 aspect-square" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageViewer;