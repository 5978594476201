import React from 'react'
import { getStatusColor } from '../../utils/status_color'
import { Pin } from '@vis.gl/react-google-maps'

const MarkerPin = React.memo(({device}) => {
  return (
    <div>
        <Pin
            background={'#051A36'} // Set color based on device status
            glyphColor={getStatusColor(device.status)} // Set glyph color to white
            borderColor={'#051A36'} // Change border if inspected
        />      
    </div>
  )
});

export default MarkerPin
