// src/redux/devicesSlice.js
import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  selectedDeviceId: null,
  inspectedDeviceId: null,
  selectedDistrictId: null,
  showImageViewer: false,
  inspectorViewState: "info",
  mapMode: "citywide",
  displayedDevices: [],
  displayedDistricts: [],
  zoomedDevices: [],
  selectedMeasurementIndex: null,
  lockedMeasurementIndex: null,
};

const mapStateSlice = createSlice({
  name: 'mapState',
  initialState,
  reducers: {
    selectDevice: (state, action) => {
      // prevent selecting the same device twice
      if (action.payload && state.selectedDeviceId === action.payload) return;

      // select the device
      state.selectedDeviceId = action.payload;

      // if there is an inspected device, replace it with the selected device
      if (state.inspectedDeviceId) {
        state.inspectedDeviceId = action.payload;
      }
    },
    setInspectedDevice: (state, action) => {
      // if inspection window is closed, reset only the inspected device
      if (!action.payload) 
      {
        state.inspectedDeviceId = null;
        state.selectedDeviceId = null;
        return;
      }

      // prevent selecting the same device twice
      if (state.inspectedDeviceId === action.payload) return;

      // set the inspected device
      state.inspectedDeviceId = action.payload;
      // set the selected device to the inspected device
      state.selectedDeviceId = action.payload;
    },
    setShowImageViewer: (state, action) => {
      state.showImageViewer = action.payload;
    },
    setInspectorViewState: (state, action) => {
      state.inspectorViewState = action.payload;
    },
    setMapMode: (state, action) => {

      const mapModes = ["citywide", "districts"];
      if (!mapModes.includes(action.payload))
      {
        console.error(`Invalid map mode: ${action.payload}`);
        return;
      }

      state.selectedDeviceId = null;
      state.inspectedDeviceId = null;
      state.selectedDistrictId = null;

      state.mapMode = action.payload;
    },
    displayDevices: (state, action) => {
      state.displayedDistricts = [];
      state.zoomedDevices = [];
      state.displayedDevices = action.payload;
    },
    displayDistricts: (state, action) => {
      state.displayedDevices = [];
      state.zoomedDevices = [];
      state.displayedDistricts = action.payload;
    },
    selectDistrict: (state, action) => {
      state.selectedDistrictId = action.payload;
    },
    setZoomedDevices: (state, action) => {
      state.zoomedDevices = action.payload;
    },
    setSelectedMeasurementIndex: (state, action) => {
      state.selectedMeasurementIndex = action.payload;
    },
    setLockedMeasurementIndex: (state, action) => {
      state.lockedMeasurementIndex = action.payload;
    },
  },
});

export const { selectDevice, setInspectedDevice, setShowImageViewer, setInspectorViewState, setMapMode, displayDevices, displayDistricts, selectDistrict, setZoomedDevices, setSelectedMeasurementIndex, setLockedMeasurementIndex } = mapStateSlice.actions;

export default mapStateSlice.reducer;
  