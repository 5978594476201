import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { useMemo, useEffect, memo } from 'react';
import MapController from './MapController';
import CustomMarker from './CustomMarker';
import { removeMarkers } from '../../redux/markerMakerSlice';
import { hslToHex } from '../../utils/colors';

// Create memoized selectors
const selectSelectedMarkerIds = state => state.markerMaker.selectedMarkerIds;
const selectCurrentMarkerType = state => state.markerMaker.currentMarkerType;
const selectLocalDeviceMarkers = state => state.markerMaker.localDeviceMarkers;
const selectLocalDistrictMarkers = state => state.markerMaker.localDistrictMarkers;

// Combine selectors
const selectMapContentData = createSelector(
  [selectSelectedMarkerIds, selectCurrentMarkerType, selectLocalDeviceMarkers, selectLocalDistrictMarkers],
  (selectedMarkerIds, currentMarkerType, localDeviceMarkers, localDistrictMarkers) => ({
    selectedMarkerIds,
    currentMarkerType,
    localDeviceMarkers,
    localDistrictMarkers
  })
);

// Move outside component to create a static cache
const districtColorCache = new Map();

const getDistrictColor = (districtId) => {
  if (!districtId) return '#ff4444'; // Red for markers without districts
  
  // Check cache first
  if (districtColorCache.has(districtId)) {
    return districtColorCache.get(districtId);
  }
  
  // Calculate new color
  const districtString = String(districtId);
  const numericValue = districtString.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const hue = (numericValue * 137.508) % 360;
  const color = hslToHex(hue, 45, 35);
  
  // Store in cache
  districtColorCache.set(districtId, color);
  return color;
};

const MapContent = ({ 
  onMapClick, 
  onMarkerClick,
  onMarkerDragEnd,
}) => {
  const dispatch = useDispatch();
  const {
    selectedMarkerIds,
    currentMarkerType,
    localDeviceMarkers,
    localDistrictMarkers
  } = useSelector(selectMapContentData);

  // Update the renderedMarkers to use onMarkerClick and onMarkerDragEnd directly
  const markers = currentMarkerType === 'device' ? localDeviceMarkers : localDistrictMarkers;
  
  // Memoize all district colors at once
  const districtColors = useMemo(() => {
    const colors = new Map();
    markers.forEach(marker => {
      if (!colors.has(marker.district)) {
        colors.set(marker.district, getDistrictColor(marker.district));
      }
    });
    return colors;
  }, [markers]); // Only recalculate when markers change

  const renderedMarkers = useMemo(() => markers.map((marker) => {
    const markerId = currentMarkerType === 'device' ? marker.sensor_id : marker.id;
    const key = `${currentMarkerType}-${markerId}`;
    const markerColor = districtColors.get(marker.district);
    
    return (
      <CustomMarker
        key={key}
        lat={Number(marker.lat)}
        lng={Number(marker.lng)}
        isSelected={selectedMarkerIds.includes(markerId)}
        onLeftClick={(e) => onMarkerClick(e, marker, false)}
        onRightClick={() => onMarkerClick(null, marker, true)}
        onDragEnd={(latLng) => onMarkerDragEnd(marker, latLng)}
        type={currentMarkerType}
        marker={marker}
        color={markerColor}
      />
    );
  }), [
    markers, 
    selectedMarkerIds, 
    onMarkerClick,
    onMarkerDragEnd,
    districtColors, // Add districtColors as dependency
    currentMarkerType // Add missing dependency
  ]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Delete' && selectedMarkerIds.length > 0) {
        dispatch(removeMarkers());
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [selectedMarkerIds, dispatch]);

  return (
    <>
      <MapController onClick={onMapClick} />
      {renderedMarkers}
    </>
  );
};

export default memo(MapContent); 