export function isMarkerInSelectionBox(marker, selectionBox, mapRef) {
  const markerRect = getMarkerRect(marker, mapRef);
  const selectionRect = getSelectionRect(selectionBox);
  return rectanglesIntersect(markerRect, selectionRect);
}

export function getMarkerRect(marker, mapRef) {
  const element = mapRef.current?.querySelector(`[data-marker-id="${marker.id}"]`);
  if (!element) return null;
  
  const rect = element.getBoundingClientRect();
  const mapRect = mapRef.current.getBoundingClientRect();
  
  return {
    left: rect.left - mapRect.left,
    top: rect.top - mapRect.top,
    right: rect.right - mapRect.left,
    bottom: rect.bottom - mapRect.top,
    width: rect.width,
    height: rect.height
  };
}

export function getSelectionRect(selectionBox) {
  return {
    left: Math.min(selectionBox.start.x, selectionBox.end.x),
    top: Math.min(selectionBox.start.y, selectionBox.end.y),
    right: Math.max(selectionBox.start.x, selectionBox.end.x),
    bottom: Math.max(selectionBox.start.y, selectionBox.end.y),
    width: Math.abs(selectionBox.end.x - selectionBox.start.x),
    height: Math.abs(selectionBox.end.y - selectionBox.start.y)
  };
}

export function rectanglesIntersect(rect1, rect2) {
  if (!rect1 || !rect2) return false;
  return !(
    rect1.left > rect2.right ||
    rect1.right < rect2.left ||
    rect1.top > rect2.bottom ||
    rect1.bottom < rect2.top
  );
} 