import React from "react";

function SensorInfo() {
  return (
    <div className="flex gap-4 self-start mt-4 font-bold text-white">
      <div className="grow">Sensor ID:</div>
      <div>23694853B</div>
      <div>Location:</div>
      <div>MARINA #77</div>
      <div>Type:</div>
      <div className="text-right">Main Culvert</div>
    </div>
  );
}

export default SensorInfo;