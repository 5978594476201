export const getStatusColor = (status) => {
    switch (status) {
      case 1:
        return '#68FD50'; // Green for status 1
      case 2:
        return '#FFDE21'; // Yellow for status 2
      case 3:
        return '#FF4136'; // Red for status 3
      default:
        return '#051A36'; // Default color if status is unknown
    }
  };