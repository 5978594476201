import React from "react";
import StatusIndicator from "./StatusIndicator";
import SensorInfo from "./SensorInfo";
import ImageViewer from "./ImageViewer";
import HistoryPanel from "./HistoryPanel";
import { useSelector } from 'react-redux';

function SensorImageViewer() {
  const statusItems = [
    { label: "Unobstructed", color: "bg-green-400" },
    { label: "A. Temp: 39c", color: "bg-green-400" },
    { label: "Clear Water", color: "bg-green-400" },
    { label: "≈ 30 cm", color: "bg-green-400" },
  ];

  const showImageViewer = useSelector((state) => state.mapState.showImageViewer);

  if (!showImageViewer) {
    return null;
  }

  return (
    <main className="absolute inset-0 flex flex-col items-end px-4 pt-4 pb-24 mx-6 mt-6 rounded bg-slate-900 max-md:pr-5 max-md:mr-2.5 max-md:max-w-full">
      <header className="flex flex-wrap gap-7 items-start self-start text-base leading-tight">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/c378ca65ad02902af88c9f7b19ce875a6e159dd7c5f88be8549803327bc1aeb1?placeholderIfAbsent=true&apiKey=89cf183a92e74cf6ac32b5c47e264d6f"
          alt=""
          className="object-contain shrink-0 w-8 aspect-square"
        />
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/6a34b98c51a8fc739cc41542728e0330bf73b3eb07bf93a0dd645c6a7f525ecd?placeholderIfAbsent=true&apiKey=89cf183a92e74cf6ac32b5c47e264d6f"
          alt="Company Logo"
          className="object-contain shrink-0 self-stretch w-32 max-w-full aspect-[1.32]"
        />
        <div className="flex flex-col mt-2 max-md:max-w-full">
          <div className="flex flex-wrap gap-4 text-black">
            <div className="grow my-auto font-bold text-white">Status:</div>
            {statusItems.map((item, index) => (
              <StatusIndicator key={index} label={item.label} color={item.color} />
            ))}
          </div>
          <SensorInfo />
        </div>
      </header>
      <section className="z-10 mt-4 w-full max-w-[1114px] max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <ImageViewer />
          <HistoryPanel />
        </div>
      </section>
      <footer className="flex gap-6 -mt-3.5 mr-20 text-base leading-tight text-white whitespace-nowrap max-md:mr-2.5">
        <div>#00002</div>
        <div className="text-right">2024/03/12</div>
      </footer>
    </main>
  );
}

export default SensorImageViewer;
