import React from 'react';
import SensorInspector from '../../device_cards/map_card/SensorInspector';
import SensorImageViewer from '../../sensor_images/SensorImageViewer';
import IconCircleButton from '../../components/icon-button';
import { CityWideIcon, DistrictIcon } from '../../components/icons';
import { useSelector, useDispatch } from 'react-redux';
import { setMapMode, displayDevices, displayDistricts } from '../../redux/mapStateSlice';
import DistrictInspector from './district_inspector/DistrictInspector';
import FilterWindow from './filter_window';
import DeviceInformation from '../../device_cards/ai_card/DeviceInformation';
import { setZoomedDevices } from '../../redux/mapStateSlice';

// OverlayParent component which contains UI buttons and sensor details
const OverlayParent = React.memo(() => {
  const devices = useSelector((state) => state.devices.devices);
  const districts = useSelector((state) => state.districts.districts);
  const mapMode = useSelector((state) => state.mapState.mapMode);
  const dispatch = useDispatch();
  const inspectedDeviceId = useSelector((state) => state.mapState.inspectedDeviceId);

  return (
    <>
      {/* Button Controls */}
      <div className="absolute top-4 left-4 flex flex-col gap-2 z-10 w-40">
        <IconCircleButton
          text="City Wide"
          colorScheme="light"
          selected={mapMode === "citywide"}
          onClick={() => {
            dispatch(setMapMode("citywide"));
            dispatch(displayDevices(devices));
          }}
          icon={() => <CityWideIcon />}
        />
        <IconCircleButton
          text="Districts"
          colorScheme="light"
          selected={mapMode === "districts"}
          onClick={() => {
            dispatch(setMapMode("districts"));
            dispatch(displayDistricts(districts));
            dispatch(setZoomedDevices(districts));
          }}
          icon={() => <DistrictIcon />}
        />
      </div>

      <div className="z-30">
        <FilterWindow />
      </div>
      {inspectedDeviceId && (
        <div className="absolute z-20 w-[1200px]"
          style={{ bottom: "30px", left: "50%", transform: "translateX(-50%)" }}
        >
          <DeviceInformation />
        </div>
      )}
      <div className="z-20">
        <DistrictInspector />
      </div>
      <div className="z-20">
        <SensorImageViewer />
      </div>
    </>
  );
});

export default OverlayParent;
