import React, { useState } from 'react';
import LastPicture from './LastPicture';
import InfoBox from './InfoBox';
import AIPriorityScore from './AIPriorityScore';
import { useSelector } from 'react-redux';
import ImageModal from './ImageModal';

function ImageBox({ device }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedMeasurementIndex = useSelector((state) => state.mapState.selectedMeasurementIndex);
  
  if (!device?.measures?.length) {
    return null;
  }
  
  const measurement = selectedMeasurementIndex !== null 
    ? device.measures[selectedMeasurementIndex]
    : device.measures[device.measures.length - 1];

  const readings = {};
  measurement.readings.forEach(reading => {
    const key = reading.key.toLowerCase().replace(' ', '_');
    readings[key] = reading.value;
    readings[`${key}_status`] = reading.status;
  });

  return (
    <>
      <main 
        className="h-full cursor-pointer"
        onClick={() => setIsModalOpen(true)}
      >
        <section className="h-full w-full flex items-center justify-center">
          <div className="relative h-full w-full">
            <div className="relative h-full w-full flex items-center justify-center">
              <img
                loading="lazy"
                src={`/images/sensor/${measurement.image}`}
                alt="Main image"
                className="max-h-full max-w-full object-contain"
              />
              <LastPicture 
                date={measurement.date} 
                time="10:34AM" 
                className="absolute top-0 left-0 text-sm" 
              />
              <div className="absolute bottom-4 left-4 right-4 flex gap-2">
                <InfoBox
                  type={device.type}
                  readings={measurement.readings}
                />
                <AIPriorityScore score={parseInt(measurement.ai_score)} />
              </div>
            </div>
          </div>
        </section>
      </main>

      {isModalOpen && (
        <ImageModal 
          device={device} 
          onClose={() => setIsModalOpen(false)} 
        />
      )}
    </>
  );
}

export default ImageBox;