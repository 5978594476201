import React from "react";
import Collapsible from 'react-collapsible';
import { useSelector, useDispatch } from "react-redux";
import { displayDevices } from "../../redux/mapStateSlice";
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid';

const SelectButton = ({ onClick, text }) => (
    <button 
        onClick={onClick}
        className="bg-dark-blue hover:bg-blue-900 text-white px-3 py-1 rounded-full text-xs font-semibold border border-dark-blue"
    >
        {text}
    </button>
);

const FilterWindow = () => {
    const dispatch = useDispatch();
    const districts = useSelector(state => state.districts.districts);
    const devices = useSelector(state => state.devices.devices);
    const filter = {
        districtIds: districts.map(district => district.id.toString()),
        statusIds: [0, 1, 2]
    }

    const mapMode = useSelector(state => state.mapState.mapMode);

    const updateDeviceList = () => {
        const newDeviceList = devices.filter(device => {
            return filter.districtIds.includes(device.district) && filter.statusIds.includes(device.status);
        });
        dispatch(displayDevices(newDeviceList));
    }

    const districtFilterChanged = (e) => {
        const { id, checked } = e.target;
        if (checked && !filter.districtIds.includes(id)) {
            filter.districtIds.push(id);
        } else if (!checked && filter.districtIds.includes(id)) {
            filter.districtIds = filter.districtIds.filter(districtId => districtId !== id);
        }

        updateDeviceList();
    }

    const statusFilterChanged = (e) => {
        const { id, checked } = e.target;
        const idNum = parseInt(id);
        if (checked && !filter.statusIds.includes(idNum)) {
            filter.statusIds.push(idNum);
        } else if (!checked && filter.statusIds.includes(idNum)) {
            filter.statusIds = filter.statusIds.filter(statusId => statusId !== idNum);
        }

        updateDeviceList();
    }

    const selectAllDistricts = () => {
        filter.districtIds = districts.map(district => district.id.toString());
        updateDeviceList();
        // Force re-render of checkboxes - only for district checkboxes
        const districtCheckboxes = document.querySelectorAll('.district-checkbox');
        districtCheckboxes.forEach(checkbox => checkbox.checked = true);
    }

    const selectNoDistricts = () => {
        filter.districtIds = [];
        updateDeviceList();
        // Force re-render of checkboxes - only for district checkboxes
        const districtCheckboxes = document.querySelectorAll('.district-checkbox');
        districtCheckboxes.forEach(checkbox => checkbox.checked = false);
    }

    const selectAllStatus = () => {
        filter.statusIds = [0, 1, 2];
        updateDeviceList();
        const statusCheckboxes = document.querySelectorAll('.status-checkbox');
        statusCheckboxes.forEach(checkbox => checkbox.checked = true);
    }

    const selectNoStatus = () => {
        filter.statusIds = [];
        updateDeviceList();
        const statusCheckboxes = document.querySelectorAll('.status-checkbox');
        statusCheckboxes.forEach(checkbox => checkbox.checked = false);
    }

    const CustomTrigger = ({ text, isOpen }) => (
        <div className="bg-white font-semibold text-base border border-dark-blue text-dark-blue px-3 py-2 mt-1 mb-1 rounded-full flex justify-between items-center group">
            {text}
            <div className="p-1 rounded-full group-hover:bg-yellow transition-colors">
                {isOpen ? (
                    <ChevronDownIcon className="h-4 w-4" />
                ) : (
                    <ChevronUpIcon className="h-4 w-4" />
                )}
            </div>
        </div>
    );

    return (
        mapMode === "citywide" && (
        <div className="absolute bottom-4 left-4 bg-white text-dark-blue p-4 rounded-xl shadow-lg">
            <Collapsible
                transitionTime={600}
                easing="ease-in-out"
                trigger={<CustomTrigger text="Districts" />}
                triggerWhenOpen={<CustomTrigger text="Districts" isOpen />}
            >
                <div className="flex gap-2 mb-2">
                    <SelectButton onClick={selectAllDistricts} text="Select All" />
                    <SelectButton onClick={selectNoDistricts} text="Select None" />
                </div>
                {districts.map(district => (
                    <div key={district.id} className="flex items-center">
                        <input 
                            type="checkbox" 
                            id={district.id} 
                            onChange={districtFilterChanged} 
                            defaultChecked 
                            className="district-checkbox w-4 h-4 accent-yellow"
                        />
                        <label htmlFor={district.id} className="ml-2 text-dark-blue">{district.name}</label>
                    </div>
                ))}
            </Collapsible>
            <Collapsible 
                transitionTime={600}
                easing="ease-in-out"
                trigger={<CustomTrigger text="Status" />}
                triggerWhenOpen={<CustomTrigger text="Status" isOpen />}
            >
                <div className="flex gap-2 mb-2">
                    <SelectButton onClick={selectAllStatus} text="Select All" />
                    <SelectButton onClick={selectNoStatus} text="Select None" />
                </div>
                <div>
                    <input type="checkbox" id="1" onChange={statusFilterChanged} defaultChecked className="status-checkbox w-4 h-4 accent-yellow" />
                    <label htmlFor="1" className="ml-2 text-dark-blue">Online</label>
                </div>
                <div>
                    <input type="checkbox" id="2" onChange={statusFilterChanged} defaultChecked className="status-checkbox w-4 h-4 accent-yellow" />
                    <label htmlFor="2" className="ml-2 text-dark-blue">Weak</label>
                </div>
                <div>
                    <input type="checkbox" id="3" onChange={statusFilterChanged} defaultChecked className="status-checkbox w-4 h-4 accent-yellow" />
                    <label htmlFor="3" className="ml-2 text-dark-blue">Offline</label>
                </div>
            </Collapsible>
        </div>
        )
    );
}

export default FilterWindow;