import React from 'react';

const AIPriorityScore = ({ score }) => {
  const getBgColor = (score) => {
    if (score > 80) return 'bg-status-error';
    if (score > 50) return 'bg-status-warning';
    return 'bg-status-success';
  };

  return (
    <div className="flex flex-col px-2 py-2 rounded bg-slate-700">
      <div className="self-center text-xs text-white">AI priority score</div>
      <div className={`px-14 py-2.5 mt-2.5 text-2xl font-bold text-center whitespace-nowrap ${getBgColor(score)} rounded text-stone-900`}>
        {score}
      </div>
    </div>
  );
};

export default AIPriorityScore;