import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import ImageBox from './ImageBox';
import { useSelector, useDispatch } from 'react-redux';
import IconCircleBox from '../../components/icon-box';
import RegularBox from '../../components/regular-box';
import { CityWideIcon } from '../../components/icons';
import { getStatusColor } from '../../utils/status_color';
import { setSelectedMeasurementIndex } from '../../redux/mapStateSlice';

function ImageModal({ device, onClose }) {
    const dispatch = useDispatch();
    const selectedIndex = useSelector(state => state.mapState.selectedMeasurementIndex);
    const [previewIndex, setPreviewIndex] = useState(null);
    const [lastClickedIndex, setLastClickedIndex] = useState(selectedIndex);

    // Helper function to handle mouse enter/leave
    const handleMouseEnter = (index) => {
        setPreviewIndex(index);
        // Temporarily show this image
        dispatch(setSelectedMeasurementIndex(index));
    };

    const handleMouseLeave = () => {
        setPreviewIndex(null);
        // Restore the last clicked image
        dispatch(setSelectedMeasurementIndex(lastClickedIndex));
    };

    const handleClick = (index) => {
        setLastClickedIndex(index);
        dispatch(setSelectedMeasurementIndex(index));
    };

    return ReactDOM.createPortal(
        <div className="fixed inset-0 bg-black bg-opacity-50 z-[9999] flex items-center justify-center p-4">
            <div className="bg-dark-blue text-white rounded-lg p-6 min-w-[600px] max-w-[90vw] max-h-[90vh] overflow-auto relative">
                {/* Close button */}
                <button 
                    onClick={onClose}
                    className="absolute top-4 right-4 text-white/70 hover:text-white"
                >
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>

                {/* Device info header */}
                <div className="flex items-center gap-4 mb-6">
                    <img src="/walterodevice.png" alt="Device" className="w-16 h-16 object-contain" />
                    <div id="device-status-info" className="flex gap-3">
                        <IconCircleBox
                            text={device.district_name + " #" + device.location}
                            colorScheme="light"
                            circlePosition="left"
                            icon={() => <CityWideIcon />}
                        />
                        <RegularBox
                            colorScheme="light"
                            text={"Sensor ID: " + device.sensor_id}
                        />
                        <IconCircleBox
                            text={"Battery: " + device.battery + "%"}
                            colorScheme="light"
                            circlePosition="left"
                            circleColor={getStatusColor(
                                device.battery < 10 ? 3 : device.battery < 20 ? 2 : 1
                            )}
                        />
                        <IconCircleBox
                            text={"Signal: " + device.signal + "%"}
                            colorScheme="light"
                            circlePosition="left"
                            circleColor={getStatusColor(
                                device.signal < 50 ? 3 : device.signal < 75 ? 2 : 1
                            )}
                        />
                    </div>
                </div>

                {/* Main content */}
                <div className="flex gap-4">
                    {/* Left side - Image */}
                    <div className="flex-1 h-[70vh]">
                        <ImageBox device={device} />
                    </div>

                    {/* Right side - History */}
                    <div className="w-80 h-[70vh] border border-white/20 rounded-lg overflow-hidden">
                        <h3 className="font-bold mb-0 p-4 bg-white/5 border-b border-white/20">History</h3>
                        <div 
                            className="space-y-2 p-4 h-[calc(70vh-60px)] overflow-y-auto 
                                scrollbar-thin scrollbar-thumb-white/20 scrollbar-track-transparent hover:scrollbar-thumb-white/30"
                            onMouseLeave={handleMouseLeave}
                        >
                            {device.measures.map((measure, index) => (
                                <div 
                                    key={index}
                                    className={`flex items-center justify-between p-2 cursor-pointer rounded transition-colors
                                        ${index === lastClickedIndex ? 'bg-white/10' : ''}
                                        ${previewIndex === index ? 'ring-1 ring-white/30' : ''}`}
                                    onClick={() => handleClick(index)}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                >
                                    <div>
                                        <div>{measure.date}</div>
                                        <div className="text-sm text-gray-500">10:34 AM</div>
                                    </div>
                                    <div className="flex items-center gap-4">
                                        <span className={`px-2 py-1 rounded ${
                                            measure.ai_score >= 80 ? 'bg-status-error/20 text-status-error' :
                                            measure.ai_score >= 50 ? 'bg-status-warning/20 text-status-warning' :
                                            'bg-status-success/20 text-status-success'
                                        }`}>
                                            Score: {measure.ai_score}
                                        </span>
                                        <button 
                                            className="text-blue-600 hover:text-blue-800"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                window.open(`/images/sensor/${measure.image}`, '_blank');
                                            }}
                                        >
                                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
}

export default ImageModal; 