import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from './Dashboard.module.css';
import InfoItem from './InfoItem';
import StatusItem from './StatusItem';
import StatusGraph from './status_graph';

const infoItems = [
  { iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/addde383b9fe2298f8e352f0a6ca52e2190e90f492194b7ca72df02ae35f623a?placeholderIfAbsent=true&apiKey=89cf183a92e74cf6ac32b5c47e264d6f", text: "DUBAÏ" },
  { iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/19a188a7af35a6fef5320b063c212ce7f9b47eb491cf2a4a8b3ccd88fc86a532?placeholderIfAbsent=true&apiKey=89cf183a92e74cf6ac32b5c47e264d6f", text: new Date().toISOString().split('T')[0] },
  { iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/72e40204077a106c4ef51035d757e5c599994a8d56e0450d4fd8c82d9ee2284c?placeholderIfAbsent=true&apiKey=89cf183a92e74cf6ac32b5c47e264d6f", text: "Sunny" },
  { iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/145ec6277ce6c711ada261339d016eadc9cc3ac0507acbff882ee81512b1634c?placeholderIfAbsent=true&apiKey=89cf183a92e74cf6ac32b5c47e264d6f", text: "35" },
];

const getStatusCounts = (devices) => {
  return {
    status1: devices.filter(device => device.status === 1).length,
    status2: devices.filter(device => device.status === 2).length,
    status3: devices.filter(device => device.status === 3).length,
  };
};

const actionIcons = [
  {
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/af61fcc655abdc6fe0134c41321aa1a12241ad298f2c550c82ec398a7a391ebc",
    path: null
  },
  {
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/02df16efc0b42ec749f821754359800bcda822502c857a3af26a183da247aaa0",
    path: null
  },
  {
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/e364f6cf8ca4b12bfb9ba2d972a637784d967003a5f48071100fa0db220d10f3",
    path: "/maker"
  },
];

function Dashboard() {
  const navigate = useNavigate();
  const devices = useSelector(state => state.devices.devices);
  const statusCounts = getStatusCounts(devices);
  const [showGraph, setShowGraph] = useState(false);

  return (
    <header className={styles.container}>
      <div className={styles.gridLayout}>
        <h1 className={styles.title}>Dashboard</h1>
        {infoItems.map((item, index) => (
          <InfoItem key={index} iconSrc={item.iconSrc} text={item.text} />
        ))}
        <div 
          className={`${styles.statusGroup} ${styles.gridItem}`}
          onMouseEnter={() => setShowGraph(true)}
          onMouseLeave={() => setShowGraph(false)}
        >
          <StatusItem color="green" value={statusCounts.status1.toString()} />
          <StatusItem color="yellow" value={statusCounts.status2.toString()} />
          <StatusItem color="red" value={statusCounts.status3.toString()} />
          {showGraph && (
            <div className={styles.graphTooltip + " statusGroup"}>
              <StatusGraph className={styles.graph} />
            </div>
          )}
        </div>
        <nav className={`${styles.actionIcons} ${styles.gridItem}`}>
          {actionIcons.map((icon, index) => (
            <img 
              key={index} 
              loading="lazy" 
              src={icon.src} 
              alt="" 
              className={`${styles.icon} ${icon.path ? 'cursor-pointer' : ''}`}
              onClick={() => icon.path && navigate(icon.path)}
            />
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Dashboard;