import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    districts: [],
    selectedDistrict: null,
};

const districtsSlice = createSlice({
    name: 'districts',
    initialState,
    reducers: {
        setDistricts: (state, action) => {
            state.districts = action.payload;
        },
        selectDistrict: (state, action) => {
            state.selectedDistrict = action.payload;
        },
    }
});

export const { setDistricts, selectDistrict } = districtsSlice.actions;
export default districtsSlice.reducer;


