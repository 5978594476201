import React, { useState, useEffect } from 'react';

const DefaultIcon = ({ className, color }) => (
  <span className={className} style={{ color }}></span>
);

const IconCircleBox = ({
  text,
  icon: Icon,
  circlePosition = 'right',
  colorScheme = 'dark',
  circleColor,
  className = '',
}) => {
  const [styles, setStyles] = useState({});

  useEffect(() => {
    const baseBoxStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontWeight: '500',
      fontSize: '1rem',
      padding: '0.5rem',
      borderRadius: '9999px',
      borderWidth: '1px',
      whiteSpace: 'nowrap', // Prevent content from wrapping
    };

    const circleStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '1rem',
      height: '1rem',
      borderRadius: '9999px',
    };

    let boxStyle = { ...baseBoxStyle };
    let iconStyle = { color: '' };

    if (colorScheme === 'dark') {
      boxStyle.backgroundColor = '#051A36';
      boxStyle.borderColor = 'white';
      boxStyle.color = 'white';
      iconStyle.color = 'white';
    } else if (colorScheme === 'light') {
      boxStyle.backgroundColor = 'white';
      boxStyle.borderColor = '#051A36';
      boxStyle.color = '#051A36';
      iconStyle.color = '#051A36';
    }

    // Modified circle color handling
    const getCircleStyles = (color) => {
      // If no color provided, use the box's background color
      if (!color) {
        return { ...circleStyle, backgroundColor: boxStyle.backgroundColor };
      }
      // If it's a hex value or CSS color, use it directly
      if (color.startsWith('#') || color.startsWith('rgb') || color.startsWith('hsl')) {
        return { ...circleStyle, backgroundColor: color };
      }
      // If it's a Tailwind class, return empty backgroundColor
      return { ...circleStyle, backgroundColor: '' };
    };

    setStyles({
      box: boxStyle,
      circle: getCircleStyles(circleColor),
      icon: iconStyle,
    });
  }, [colorScheme, circleColor]);

  const IconComponent = Icon || DefaultIcon;

  return (
    <div
      style={styles.box}
      className={className}
    >
      {circlePosition === 'left' && (
        <span style={styles.circle} className={circleColor || ''}>
          <div style={styles.icon}>
            <IconComponent style={styles.icon} className="w-8 h-8" />
          </div>
        </span>
      )}
      <span className="mx-2 flex-grow text-left">{text}</span>
      {circlePosition === 'right' && (
        <span style={styles.circle} className={circleColor || ''}>
          <div style={styles.icon}>
            <IconComponent style={styles.icon} className="w-8 h-8" />
          </div>
        </span>
      )}

    </div>
  );
};

export default IconCircleBox;