import React, { useState, useEffect } from 'react';

const RegularBox = ({
  text,
  colorScheme = 'dark',
  className = '',
}) => {
  const [styles, setStyles] = useState({});

  useEffect(() => {
    const baseBoxStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: '500',
      fontSize: '1rem',
      padding: '0.25rem',
      borderRadius: '9999px',
      borderWidth: '1px',
      whiteSpace: 'nowrap', // Prevent content from wrapping
    };

    let boxStyle = { ...baseBoxStyle };

    if (colorScheme === 'dark') {
      boxStyle.backgroundColor = '#051A36';
      boxStyle.borderColor = 'white';
      boxStyle.color = 'white';
    } else if (colorScheme === 'light') {
      boxStyle.backgroundColor = 'white';
      boxStyle.borderColor = '#051A36';
      boxStyle.color = '#051A36';
    }

    setStyles({
      box: boxStyle,
    });
  }, [colorScheme]);

  return (
    <div
      style={styles.box}
      className={className}
    >
      <span className="mx-2 flex-grow text-left">{text}</span>
    </div>
  );
};

export default RegularBox;