import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  devices: [],
  districts: []
};

const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    setImages: (state, action) => {
      state.devices = action.payload.devices;
      state.districts = action.payload.districts;
    }
  }
});

export const { setImages } = imagesSlice.actions;
export default imagesSlice.reducer; 