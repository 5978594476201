import React from "react";
import DeviceInfo from "./DeviceInfo";
import DeviceList from "./DeviceList";
import { useSelector, useDispatch } from "react-redux";
import { selectDistrict, displayDistricts, setZoomedDevices } from "../../../redux/mapStateSlice";

function DistrictInspector() {
    const dispatch = useDispatch();

    const selectedDistrictId = useSelector(state => state.mapState.selectedDistrictId);
    const districts = useSelector(state => state.districts.districts);
    const selectedDistrict = districts.find(district => district.id === selectedDistrictId);

    const mapMode = useSelector(state => state.mapState.mapMode);
    const inspectedDeviceId = useSelector(state => state.mapState.inspectedDeviceId);

    if (!selectedDistrictId) return null;
    if (mapMode !== "districts") return null;
    if (inspectedDeviceId) return null;

    const onClose = () => {
        dispatch(selectDistrict(null));
        dispatch(displayDistricts(districts));
        dispatch(setZoomedDevices(districts));
    }

    return (
        <div className="relative rounded-2xl">
            <main
                className="flex flex-col shadow-[0px_4px_4px_rgba(0,0,0,0.25)] absolute w-[900px]"
                style={{ bottom: "30px", left: "50%", transform: "translateX(-50%)" }}
            >
                <div className=" text-base text-black shadow-[0px_4px_4px_rgba(0,0,0,0.25)] rounded-2xl">
                    <section className="flex h-auto items-start px-4 pt-4 w-full bg-white rounded max-md:pr-5 max-md:max-w-full max-h-[270px] rounded-2xl">
                        <div className="flex flex-col gap-5 mb-4 justify-between items-start font-bold max-w-[1046px] max-md:max-w-full">
                            <div id="district-header" className="flex gap-5 text-2xl text-center">
                                <img loading="lazy" onClick={onClose} src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a10688731a57a521de588458d725993b5ff6cefc83adbace0bbd5c4ca3efcaa?placeholderIfAbsent=true&apiKey=89cf183a92e74cf6ac32b5c47e264d6f" alt="" className="object-contain shrink-0 self-start w-8 aspect-square" />
                                <h1 className="basis-auto">{selectedDistrict.name}</h1>
                            </div>
                            <img id="district-image" loading="lazy" src={`/images/districts/${selectedDistrict.image}`} alt={selectedDistrict.name} className="object-contain rounded max-h-[180px]" />
                        </div>
                        <div className="gap-4 max-h-[230px] overflow-y-auto flex-1">
                            <DeviceList />
                        </div>
                    </section>
                </div>
            </main>
        </div>
    );
}

export default DistrictInspector;