import React, { useCallback, useRef } from 'react';
import { AdvancedMarker, InfoWindow } from '@vis.gl/react-google-maps';
import MarkerPin from './marker_pin';
import MarkerPinSelected from './marker_pin_selected';
import { useDispatch } from 'react-redux';
import { selectDevice } from '../../redux/mapStateSlice';
import DeviceCard from './marker_device_card';


const DeviceMarker = React.memo(({ device, setMarkerRef, isInspected, isSelected}) => {
  const dispatch = useDispatch();
  const markerRef = useRef(null);

  const handlePinClick = useCallback(() => {
    if (isInspected) return;
    dispatch(selectDevice(device.sensor_id));
  }, [isInspected, dispatch]);

  const ref = useCallback(
    (marker) =>
      {
        markerRef.current = marker;
        setMarkerRef(marker, device.sensor_id);
      },
    [setMarkerRef, device.sensor_id]
  );

  return (
      <AdvancedMarker
        onClick={handlePinClick}
        key={device.sensor_id}
        position={{ lat: device.lat, lng: device.lng }}
        ref={ref}
      >
        <div>
          {isInspected ? (
            <MarkerPinSelected device={device} />
          ) : (
            <MarkerPin device={device} />
          )}
        </div>
        {isSelected && !isInspected &&
          <InfoWindow 
            anchor={markerRef.current}
            headerContent={<div className="text-lg font-semibold -mt-1">{device.district_name} #{device.location}</div>}
            onCloseClick={() => {
              dispatch(selectDevice(null));
            }}
          >
            <DeviceCard device={device} />
          </InfoWindow>
        }
      </AdvancedMarker>
  );
});

export default DeviceMarker;
